<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 20% 5%' : 'padding: 8% 5%'
    ">
    <vs-row style="padding-bottom: 2%" justify="center">
      <h1>Support</h1>
    </vs-row>
    <v-list style="padding-bottom: 5%">
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="font-size: 24px">Size & Shipping</v-list-item-title>
          </v-list-item-content>
        </template>
        <div style="padding-left: 2%">
          <v-list-item style="font-size: 14px">
            At {{ BRAND_NAME }}, products are crafted to fit perfectly, with sizes you can trust for a true-to-size experience every time. You can also customize your dress with your measurements.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            It takes around {{ DELIVERY_TIME }} business days to deliver the product.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Shipping Fee inside Dhaka: <i class="fa-solid fa-bangladeshi-taka-sign" style="margin-left: 4px;"></i> {{ DHAKA_DELIVERY_FEE }}
          </v-list-item>
          <v-list-item style="font-size: 14px">
            Shipping Fee outside Dhaka: <i class="fa-solid fa-bangladeshi-taka-sign" style="margin-left: 4px;"></i> {{ OUTSIDE_DHAKA_DELIVERY_FEE }}
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="font-size: 24px">Return & Enchanges</v-list-item-title>
          </v-list-item-content>
        </template>
        <div style="padding-left: 2%">
          <v-list-item style="font-size: 14px">
            You can return the product if it's not to your liking.
          </v-list-item>
          <v-list-item style="font-size: 14px">
            As all the dresses are custom made for you with your measurements,
            we do not accept any exchanges.
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="font-size: 24px">FAQ.</v-list-item-title>
          </v-list-item-content>
        </template>
        <div style="padding-left: 2%">
          <v-list>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px">Is delivery fee included in the price?</v-list-item-title>
                </v-list-item-content>
              </template>
              <div style="padding-left: 2%">
                <v-list-item style="font-size: 14px">
                  No, delivery fee will be added during the checkout.
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px">What payment methods are available?</v-list-item-title>
                </v-list-item-content>
              </template>
              <div style="padding-left: 2%">
                <v-list-item style="font-size: 14px">
                  Currently you can either pay with Cash on delivery or Bkash payment.
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px">How do I contact customer service?</v-list-item-title>
                </v-list-item-content>
              </template>
              <div style="padding-left: 2%">
                <v-list-item style="font-size: 14px">
                  You can reach out to us via Email: {{ BRAND_EMAIL }}
                </v-list-item>
                <v-list-item style="font-size: 14px">
                  You can also message us on Facebook or Instagram.
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 18px">How do I check the status of my order?</v-list-item-title>
                </v-list-item-content>
              </template>
              <div style="padding-left: 2%">
                <v-list-item style="font-size: 14px">
                  If you have an account with {{ BRAND_NAME }}, you can login and check your profile page. Make sure to order after creating account with us or link your orders in your profile.
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </div>
      </v-list-group>
    </v-list>
  </div>
</template>
<script>

import { BRAND_NAME, DHAKA_DELIVERY_FEE, OUTSIDE_DHAKA_DELIVERY_FEE, DELIVERY_TIME, BRAND_EMAIL } from '@/plugins/utils.js';

export default {
  name: 'Support',
  components: {},
  data: () => ({
    BRAND_NAME,
    DHAKA_DELIVERY_FEE,
    OUTSIDE_DHAKA_DELIVERY_FEE,
    DELIVERY_TIME,
    BRAND_EMAIL
  }),
  computed: {},
};
</script>
